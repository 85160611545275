import React, { useCallback, useEffect, useState } from 'react';

import { NotificationsNone } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useRecoilState } from 'recoil';

import { SearchBarStyle, SearchButtonStyle } from '@/components/SearchBar/css';
import { placeholderState } from '@/recoil/atoms/placeholderState';
import { searchWordSelector } from '@/recoil/selectors/searchwordSelector';
import Style from '@/shared/styles';

type Props = {
  iconBackgroundColor?: string;
  width?: string;
};

const SearchBar: React.FC<Props> = ({ iconBackgroundColor, width }) => {
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchWordRecoilState, setSearchWordRecoilState] = useRecoilState(searchWordSelector);
  const [placeholder] = useRecoilState(placeholderState);

  const onClickClear = useCallback(() => {
    setSearchWord('');
    setSearchWordRecoilState('');
  }, [setSearchWord, setSearchWordRecoilState]);

  const onClickSearch = useCallback(() => {
    setSearchWordRecoilState(searchWord);
  }, [setSearchWordRecoilState, searchWord]);

  const onChangeInput = useCallback((searchWordValue: string) => {
    setSearchWord(searchWordValue);
  }, []);

  const onKeydownSearch = useCallback(
    (key: string) => {
      key === 'Enter' && onClickSearch();
    },
    [onClickSearch],
  );

  useEffect(() => {
    setSearchWord(searchWordRecoilState);
  }, [searchWordRecoilState]);

  return (
    <SearchBarStyle>
      <TextField
        placeholder={placeholder}
        size="medium"
        sx={{
          width: width ?? '250px',
          height: '35px',
          '& .MuiOutlinedInput-root': {
            height: '35px',
            '&:hover fieldset': {
              borderColor: 'lightgrey',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'lightgrey',
            },
          },
        }}
        autoFocus
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton disableRipple size="small" onClick={() => onClickClear()}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
          style: {
            paddingRight: 2,
            fontSize: '1.2rem',
          },
        }}
        value={searchWord}
        onChange={(event) => onChangeInput(event.target.value)}
        onKeyDown={(event) => onKeydownSearch(event.key)}
      />
      <SearchButtonStyle onClick={() => onClickSearch()} iconBackgroundColor={iconBackgroundColor}>
        <SearchIcon fontSize="large" />
      </SearchButtonStyle>
      <SearchButtonStyle
        onClick={() => window.open(import.meta.env.VITE_NOTICE_URL)}
        iconBackgroundColor={Style.color.white}
      >
        <NotificationsNone style={{ fontSize: 28 }} />
      </SearchButtonStyle>
    </SearchBarStyle>
  );
};

export default SearchBar;
