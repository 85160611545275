import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { NotificationsNone } from '@mui/icons-material';
import { useQuery } from 'react-query';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  InputCheckboxLabelStyle,
  InputCheckboxStyle,
  MessageBodyStyle,
  MessageLabelStyle,
  MessageLinkStyle,
  MessageNoticeStyle,
  DefaultConditionStyle,
} from './css';

import { noticeDialogSelector } from '@/recoil/selectors/noticeDialogSelector';
import api from '@/shared/api';
import Button from '@/shared/components/Button';
import ModalTemplate from '@/shared/components/DialogTemplate';
import { constants } from '@/shared/constants';
import { NoticeGetResponseType } from '@/shared/types/api/api.dashboard';

const NoticeDialog: React.FC = () => {
  const { handleClose, open } = useRecoilValue(noticeDialogSelector);
  const setNoticeDialogState = useSetRecoilState(noticeDialogSelector);

  const [isChecked, setIsChecked] = useState(true);
  const handleChange = (event: ChangeEvent<HTMLInputElement>, id: number) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      localStorage.setItem(constants.localstorage().hiddenNoticeId, String(id));
    } else {
      localStorage.removeItem(constants.localstorage().hiddenNoticeId);
    }
  };

  const [notices, setNotices] = useState<NoticeGetResponseType[]>([]);

  const onClose = useCallback(() => {
    setNoticeDialogState({ open: false });
    handleClose && handleClose();
  }, [handleClose, setNoticeDialogState]);

  const { data, isLoading, isSuccess, isError, refetch } = useQuery<NoticeGetResponseType[]>(
    ['get-dashboard-notice'],
    api.dashboard.notice.get,
    {
      enabled: open,
    },
  );

  useEffect(() => {
    if (isLoading || isError) {
      setNotices([]);
    }

    if (isSuccess) {
      setNotices(data);
      setIsChecked(false);
    }
  }, [isLoading, isSuccess, data, isError, setNotices]);

  useEffect(() => {
    if (open) refetch();
  }, [open, refetch]);
  return (
    <ModalTemplate
      open={open}
      handleClose={onClose}
      width={650}
      title={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <NotificationsNone style={{ fontSize: 35 }} />
          お知らせが更新されました
        </div>
      }
      contents={
        <MessageBodyStyle>
          <>
            {notices.length <= 0 ? (
              <div>お知らせはありません。</div>
            ) : (
              <>
                <MessageLabelStyle>{notices[0].date}</MessageLabelStyle>
                <MessageNoticeStyle>
                  <a
                    href={notices[0].url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    {notices[0].title}
                  </a>
                </MessageNoticeStyle>
              </>
            )}
            <MessageLinkStyle>
              <a
                href={import.meta.env.VITE_NOTICE_URL}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                …他のお知らせを確認する
              </a>
            </MessageLinkStyle>
            <DefaultConditionStyle>
              <InputCheckboxLabelStyle isChecked={isChecked}>
                <InputCheckboxStyle
                  type="checkbox"
                  checked={isChecked}
                  onChange={(e) => handleChange(e, notices[0].id)}
                />
                このお知らせを次回以降表示しない。
                <br />
                ※お知らせが更新されれば再度表示されます
              </InputCheckboxLabelStyle>
            </DefaultConditionStyle>
          </>
        </MessageBodyStyle>
      }
      footer={<Button text="閉じる" height={40} width={150} onClick={onClose} variant="outlined" />}
    />
  );
};

export default NoticeDialog;
