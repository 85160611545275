import { constants } from '@/shared/constants';

export const redirectLoginForm = (isSaveCurrentPath?: boolean) => {
  if (isSaveCurrentPath && window && window.location) {
    let currentPath = window.location.pathname;
    if (window.location.search) currentPath += window.location.search;
    localStorage.setItem(constants.localstorage().currentPath, currentPath);
  }
  localStorage.removeItem(constants.localstorage().hiddenNoticeId);
  window.location.href = import.meta.env.VITE_LOGIN_URL;
};
