import styled from '@emotion/styled';

import Style from '@/shared/styles';

export const MessageBodyStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100px;
  font-size: 1.8rem;
  font-weight: 600;
  margin-left: 30px;
  margin-bottom: 10px;
`;

export const MessageLabelStyle = styled.div`
  color: ${Style.color.black};
  margin-top: 10px;
  text-align: left;
`;

export const MessageNoticeStyle = styled.div`
  color: ${Style.color.blue};
  margin-top: 10px;
  white-space: pre-line;
  text-align: left;
  &:hover {
    text-decoration: underline;
  }
`;

export const MessageLinkStyle = styled.div`
  font-size: 1.5rem;
  color: ${Style.color.blue};
  margin-top: 10px;
  text-align: right;
  margin-right: 30px;
  &:hover {
    text-decoration: underline;
  }
`;

export const DefaultConditionStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

type DefaultConditionStyleType = {
  isChecked: boolean;
};

export const InputCheckboxLabelStyle = styled.label<DefaultConditionStyleType>`
  color: ${({ isChecked }) => (isChecked ? Style.color.red : Style.color.dimGray)};
  font-weight: normal;
  font-size: 1.5rem;
  vertical-align: bottom;
  padding-top: 3px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;
export const InputCheckboxStyle = styled.input`
  cursor: pointer;
`;
