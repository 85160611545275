import { selector, DefaultValue } from 'recoil';

import { noticeDialogState } from '../atoms/noticeDialogState';

import { NoticeDialogType } from '@/shared/types/common.type';

export const noticeDialogSelector = selector<NoticeDialogType>({
  key: 'noticeDialogSelector',
  get: ({ get }) => get(noticeDialogState),
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue || !newValue.open) {
      reset(noticeDialogState);
    }
    set(noticeDialogState, newValue);
  },
});
