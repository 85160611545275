import React, { useState } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { RecoilRoot } from 'recoil';

import BlacklistRestrictionDialog from '@/components/BlacklistRestrictionDialog';
import FunctionalRestrictionDialog from '@/components/FunctionalRestrictionDialog';
import Loading from '@/components/Loading';
import MessageDialog from '@/components/MessageDialog';
import NoticeDialog from '@/components/NoticeDialog';
import { DebugObserver } from '@/recoil/debugTool';
import Error from '@/screens/Error';
import Routing from '@/screens/Routing';
import { AxiosClientProvider } from '@/shared/api/axios';

declare module 'react-query/types/react/QueryClientProvider' {
  interface QueryClientProviderProps {
    children?: React.ReactNode;
  }
}

const App: React.FC = () => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: 0,
            suspense: false,
            refetchOnWindowFocus: false,
            cacheTime: 0,
            staleTime: 0,
          },
        },
      }),
  );
  return (
    <RecoilRoot>
      {/* NOTE: 開発用(Recoil Debug) */}
      {import.meta.env.VITE_DEBUG_ENABLE === '1' && <DebugObserver />}
      <ErrorBoundary FallbackComponent={Error}>
        <AxiosClientProvider>
          <QueryClientProvider client={queryClient}>
            <Routing />
            <NoticeDialog />
            {/* NOTE: 開発用(ReactQuery Debug) */}
            {import.meta.env.VITE_DEBUG_ENABLE === '1' && (
              <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
            )}
          </QueryClientProvider>
        </AxiosClientProvider>
      </ErrorBoundary>
      <MessageDialog />
      <FunctionalRestrictionDialog />
      <BlacklistRestrictionDialog />
      <Loading />
    </RecoilRoot>
  );
};

export default App;
