import { atom } from 'recoil';

import { NoticeDialogType } from '@/shared/types/common.type';

export const noticeDialogState = atom<NoticeDialogType>({
  key: 'noticeDialog',
  default: {
    open: false,
  },
});
