export const localstorage = () => ({
  userId: 'userId',
  serviceType: 'serviceType',
  authorizeState: 'authorizeState',
  groups: `oa-compliance-group-${localStorage.getItem('userId')}`,
  folders: `oa-compliance-folder-${localStorage.getItem('userId')}`,
  authInformation: {
    accessToken: `access_token`,
    refreshToken: `refresh_token`,
  },
  currentPath: 'currentPath',
  isThisDisplayErase: `isThisDisplayErase`,
  checkResultsDisplaySetting: `checkResultsDisplaySetting`,
  checkResultsFilter: `checkResultsFilter`,
  checkResultsSort: `checkResultsSort`,
  checkResultsPageSize: `checkResultsPageSize`,
  hiddenNoticeId: `hiddenNoticeId`,
  firstDisplayNoticeFlag: `firstDisplayNoticeFlag`,
});
