import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { datadogRum } from '@datadog/browser-rum';
import jwtDecode from 'jwt-decode';

import App from '@/screens/App';
import api from '@/shared/api';
import { platformLoginCheck } from '@/shared/api/platform';
import { constants } from '@/shared/constants';
import { serviceName } from '@/shared/constants/code';
import { worker } from '@/shared/mocks/browser';
import { AuthType } from '@/shared/types/common.type';
import { redirectLoginForm } from '@/shared/utils/redirectLogin';

const bootstrapApp = async () => {
  // Activate Mocking API
  if (import.meta.env.VITE_MOCK_ENABLE === '1') {
    await worker.start({
      onUnhandledRequest: 'bypass',
    });
  }
  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get('code');
  const state = queryParams.get('state');
  const localStorageRefreshToken = localStorage.getItem(
    constants.localstorage().authInformation.refreshToken,
  );
  if (!code && !localStorageRefreshToken) redirectLoginForm(true);
  if (!state) localStorage.removeItem(constants.localstorage().authorizeState);
  if (code || localStorageRefreshToken) {
    try {
      let accessToken;
      let refreshToken;
      // NOTE:ログイン時
      if (code) {
        // 初回ログインになるのでお知らせ表示する
        localStorage.removeItem(constants.localstorage().firstDisplayNoticeFlag);
        const data = await api.auth.authorizeUser(code!, state).catch((e) => {
          redirectLoginForm();
          throw e;
        });
        accessToken = data.access_token;
        refreshToken = data.refresh_token;
        localStorage.removeItem(constants.localstorage().isThisDisplayErase);
        // NOTE:初回ロード時にトークンをリフレッシュする
      } else if (localStorageRefreshToken) {
        const data = await api.auth.refreshAccessToken(localStorageRefreshToken).catch((e) => {
          redirectLoginForm(true);
          throw e;
        });
        accessToken = data.access_token;
        refreshToken = data.refresh_token;
      }
      if (!accessToken || !refreshToken) throw Error('not found accessToken');
      const decodedAccessToken: AuthType = jwtDecode(accessToken);
      if (decodedAccessToken) {
        // 環境変数とアクセストークンのサービスタイプが一致するかチェック
        if (decodedAccessToken.serviceType !== serviceName[import.meta.env.VITE_SERVICE_NAME]) {
          throw Error('service type not matching');
        }
        if (!decodedAccessToken.isValidRemoteIp) {
          window.location.href = import.meta.env.VITE_PORTAL_TOP_URL;
        }
        localStorage.setItem(constants.localstorage().userId, String(decodedAccessToken.userId));
        localStorage.setItem(
          constants.localstorage().serviceType,
          String(decodedAccessToken.serviceType),
        );
        localStorage.setItem(constants.localstorage().authInformation.accessToken, accessToken);
        localStorage.setItem(constants.localstorage().authInformation.refreshToken, refreshToken);
        if (state) {
          localStorage.setItem(
            constants.localstorage().authorizeState,
            JSON.stringify(JSON.parse(decodeURIComponent(state))),
          );
        }

        if (
          (import.meta.env.VITE_SERVICE_NAME === 'compliance' &&
            ['prd', 'stg'].includes(import.meta.env.VITE_DD_NODE_ENV)) ||
          (import.meta.env.VITE_SERVICE_NAME === 'reference' &&
            ['stg'].includes(import.meta.env.VITE_DD_NODE_ENV))
        ) {
          const prefix = import.meta.env.VITE_SERVICE_NAME === 'reference' ? 'r' : '';
          datadogRum.setUser({
            id: `${prefix}${decodedAccessToken.userId || 'id無し'}`,
            name: `${prefix}${decodedAccessToken.userId || 'id無し'}`,
            contract_type: `${decodedAccessToken.contract.type}`,
            payment_type: `${decodedAccessToken.contract.paymentType}`,
          });

          datadogRum.init({
            applicationId: import.meta.env.VITE_DD_RUM_APPLICATION_ID,
            clientToken: import.meta.env.VITE_DD_RUM_CLIENT_TOKEN,
            site: 'ap1.datadoghq.com',
            service: import.meta.env.VITE_SERVICE_NAME,
            env: import.meta.env.VITE_DD_NODE_ENV,
            version: '1.0.0',
            sessionSampleRate: 100,
            sessionReplaySampleRate: 100,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input',
            enableExperimentalFeatures: ['clickmap', 'feature_flags'],
            allowedTracingUrls: [
              { match: import.meta.env.VITE_BASE_URL, propagatorTypes: ['tracecontext'] },
            ],
          });

          datadogRum.startSessionReplayRecording();
        }
      }
    } catch {
      localStorage.removeItem(constants.localstorage().userId);
      localStorage.removeItem(constants.localstorage().serviceType);
      localStorage.removeItem(constants.localstorage().authorizeState);
      localStorage.removeItem(constants.localstorage().authInformation.accessToken);
      localStorage.removeItem(constants.localstorage().authInformation.refreshToken);
      localStorage.removeItem(constants.localstorage().currentPath);
      localStorage.removeItem(constants.localstorage().isThisDisplayErase);
      redirectLoginForm();
    }
  }
  // NOTE:platform側のセッションチェック
  await platformLoginCheck();
  setInterval(() => platformLoginCheck(), 3 * 1000 * 60 * 60);

  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>,
  );
};

bootstrapApp();
